import { safeFetchAPI } from '@/api';
import { userUtils, utils } from '@/helpers';
import { Setter } from '@/legacy-types';
import { SignupForm, SignupFormContentAndDesign } from '@/types';
import { clone, isEqual, merge } from 'lodash';
import { create } from 'zustand';

export interface SignupBuilderStoreState {
	loading: boolean;
	currentStoreID: number;
	signupForm?: Partial<SignupForm>;
	storeSettings?: Partial<SignupFormContentAndDesign>;
	ogSignupForm?: Partial<SignupForm> | undefined;
}

interface EditingTemplateActions {
	setEditingStoreID: (storeID: number | string | undefined) => void;
	setEditingSignupForm: (template?: Setter<Partial<SignupForm | undefined>>) => void;
	setOgSignupForm: (template?: Partial<SignupForm>) => void;

	hasChanged: () => boolean;

	getStoreSettings: (storeID?: number, merge?: boolean) => Partial<SignupFormContentAndDesign> | undefined;
	setStoreSettings: (next: Setter<Partial<SignupFormContentAndDesign> | undefined>, storeID?: number) => void;
	clearStoreSettings: (storeID?: number) => void;

	saveForm: () => Promise<SignupForm | undefined>;
	reset: () => void;
}

export type SignupBuilderStore = SignupBuilderStoreState & EditingTemplateActions;

export const DEFAULT_STORE_ID = -1;
const initialState: SignupBuilderStoreState = {
	loading: true,
	signupForm: undefined,
	currentStoreID: DEFAULT_STORE_ID,
	ogSignupForm: undefined,
};

export const useSignupBuilderStore = create<SignupBuilderStore>((set, get) => ({
	...initialState,

	hasChanged: () => {
		const { signupForm, ogSignupForm } = get();
		return !isEqual(signupForm, ogSignupForm);
	},

	setEditingStoreID: (editingStoreID) => {
		const numEditingStoreID = Number(editingStoreID);
		set({ currentStoreID: numEditingStoreID || DEFAULT_STORE_ID });
	},

	setOgSignupForm: (template?: Partial<SignupForm>) => {
		set({ ogSignupForm: template });
	},

	setEditingSignupForm: (editingTemplate) => {
		const currEditingTemplate = get().signupForm;
		const nextEditingTemplate = typeof editingTemplate === 'function' ? editingTemplate(currEditingTemplate) : editingTemplate;
		if (userUtils.debugMode()) {
			console.log('--------------------------------');
			console.log('signupForm', nextEditingTemplate);
			console.log('--------------------------------');
		}
		set({ signupForm: nextEditingTemplate, loading: false });
	},

	getStoreSettings(storeID, noMerge = false) {
		const { signupForm, currentStoreID } = get();
		const useStoreID = storeID ?? currentStoreID ?? DEFAULT_STORE_ID;
		const storeSettings = signupForm?.settingsMap?.[useStoreID];

		if (noMerge || useStoreID === DEFAULT_STORE_ID) {
			return storeSettings;
		}

		const globalSettings = signupForm?.settingsMap?.[DEFAULT_STORE_ID];
		return merge({}, globalSettings, storeSettings);
	},

	setStoreSettings(next, storeID) {
		const { signupForm, currentStoreID } = get();
		const useStoreID = storeID ?? currentStoreID ?? DEFAULT_STORE_ID;

		const currStoreSettings = signupForm?.settingsMap?.[useStoreID];
		const nextStoreSettings: Partial<SignupFormContentAndDesign> | undefined = typeof next === 'function' ? next(currStoreSettings) : next;

		if (nextStoreSettings === undefined) {
			return;
		}

		set({
			signupForm: {
				...signupForm,
				settingsMap: { ...signupForm?.settingsMap, [useStoreID]: nextStoreSettings },
			},
		});
	},

	clearStoreSettings(storeID) {
		const { signupForm, currentStoreID } = get();
		const useStoreID = storeID ?? currentStoreID ?? DEFAULT_STORE_ID;

		if (useStoreID === DEFAULT_STORE_ID) {
			// Reset all stores
			set({ signupForm: { ...signupForm, settingsMap: {} } });
			return;
		}

		if (!signupForm?.settingsMap?.[useStoreID]) {
			return;
		}

		const settingsMap = { ...signupForm?.settingsMap };
		delete settingsMap[useStoreID];

		set({ signupForm: { ...signupForm, settingsMap } });
	},

	saveForm: async () => {
		set({ loading: true });

		const { signupForm } = get();

		const isCopying = window.location.search.includes('copy');
		const isCreating = !signupForm?.id || signupForm?.id === DEFAULT_STORE_ID || isCopying;
		if (isCreating || isCopying) {
			// biome-ignore lint/performance/noDelete: No performance impact
			delete signupForm?.id;
		}

		const [form, error] = await safeFetchAPI('/signup-forms/:uid', {
			method: isCreating ? 'POST' : 'PUT',
			payload: signupForm,
		});

		if (error) {
			set({ loading: false });
			utils.toaster.addToast?.({
				message: error,
				type: 'danger',
				placement: 'top',
			});
			return signupForm;
		}

		if (isCopying) {
			const url = new URL(window.location.href);
			url.searchParams.delete('copy');
			window.history.pushState({}, '', url.toString());
		}

		set({ loading: false, signupForm: form, ogSignupForm: clone(form) });
		utils.toaster.addToast?.({
			message: 'Form saved',
			type: 'success',
			placement: 'top',
		});
		return form;
	},

	reset: () => set(initialState),
}));
